<template>
	<div class=container>
		<ul class=sidebar v-if="clb">
			<b>Research Areas</b>
			<li v-for="category in categories" :key="category.id"> <router-link class=sidetext
					:to="{ path: '/projects/categories', query: { cid: category.title } }">{{ category.title }}</router-link></li>
		</ul>
		<table class=table width="900" border="0" cellspacing="30" cellpadding="0">

			<tbody>
				<tr>
					<td>
						<div align="center">
							<font size="+2">Gigapixel Computational Imaging</font>
						</div>
					</td>
				</tr>
				<tr align="center">
					<td>
						<center>
							<img id="figure_1"
								src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/teaser.jpg"
								alt="Image failed to load">
							<div style="text-align: justify;"><span style="font-family:
			sans-serif;">Figure 1: <b>A 1.7 gigapixel image</b> captured using
									the implementation shown in Figure 4. The
									image dimensions are 82,000 x 20,000 pixels, and the scene occupies
									a 126x32 degree FOV. From left to right, insets reveal the label
									of a resistor on a PCB board, the stippling print pattern on a
									dollar bill, a miniature 2D barcode pattern, and the fine ridges of
									a fingerprint on a remote control. The insets are generated by
									applying a 60x-200x digital zoom to the above gigapixel
									image.</span>
							</div>
						</center>
					</td>
				</tr>
				<tr>
					<td id="Publications">
						<h3>Publications</h3>
						<blockquote>
							<b>"Gigapixel Computational Imaging," </b>
							<br>O. Cossairt, D. Miau and S.K. Nayar,
							<br>IEEE International Conference on Computational Photography (ICCP),
							<br>Mar. 2011.<br>
							[<a href="/Statics/publications/pdfs/Cossairt_ICCP11.pdf"
								target="new">PDF</a>]
							[<a href="/reference?bid=256">bib</a>]
							[<a href="/copyright">©</a>]
							<br><br>
							<b>"A Scaling Law for Computational Imaging Using Spherical Optics," </b>
							<br>O. Cossairt, D. Miau and S.K. Nayar, <br>OSA Journal of Optical Society America,
							<br>Nov. 2011.<br>
							[<a href="/Statics/publications/pdfs/Cossairt_JOSA11.pdf"
								target="new">PDF</a>]
							[<a href="/reference?bid=260">bib</a>]
							[<a href="/copyright">©</a>]
							<br><br>
						</blockquote>
					</td>
				</tr>

				<tr>
					<td align="left">
						<h3 id="Project Description">Project Description</h3>

						<p>Today's high-resolution cameras capture images with pixel
							counts in the tens of millions. When digital cameras can produce images with
							billions of pixels, they will usher in a new era for photography. A gigapixel
							image has such a tremendous amount of information that one can explore minute
							details of the scene (see Figure 1). Gigapixel images are fascinating because
							they capture orders of magnitude more detail than the human eye, revealing
							information that was completely imperceptible to the photographer at the time
							of capture. At present, highly specialized gigapixel imaging systems are being
							developed for aerial surveillance.</p>

						<p>Why are there no gigapixel cameras commercially available today? CMOS and
							CCD technologies have improved to the point that imaging sensors with pixels in
							the 1µm range have been demonstrated. It is
							certainly within the reach of manufacturing technology to produce sensors with
							1 billion pixels. On the other hand, it remains a huge challenge to design and
							manufacture lenses which have the resolving power to match the resolution of
							such a sensor. This is because the number of resolvable points for a lens,
							referred to as the Space-Bandwidth Product (SBP),
							is <i>fundamentally limited by geometrical aberrations</i>. SBP is a unit-less
							quantity that tells us the number of distinct points which can be measured over
							a given FOV. Ideally, all lenses would be diffraction-limited so that
							increasing the scale of a lens while keeping FOV fixed would increase SBP.
							Unfortunately, SBP reaches a limit due to geometrical aberrations. </p>

						<p>There are two common approaches that are taken to increase SBP in the face
							of this fundamental limit. The first is to increase the scale of the camera to
							a very large format. Two notable large format gigapixel cameras are Microsoft
							Research Asia's <a href="http://www.dgcam.org/">dgCam</a>, and the
							<a href="http://www.gigapxl.org/">Gigapxl project</a> camera. These are special
							purpose cameras that are extremely large (FL &gt; 500mm) and therefore not
							suitable for commercial photography. The second approach is to increase
							complexity as a lens is scaled up. This approach was taken by Marks and Brady,
							who proposed a 7-element large format monocentric lens called the Gigagon.
							We take a different approach: we show how computations can be used to achieve
							the desired resolution while reducing lens complexity and camera size.
						</p>

						<p><b>Scaling Laws for Lenses</b>. Lohmann originally observed that lenses obey
							certain scaling laws that determine how resolution increases as a function of
							lens size. Consider a lens with focal length f,
							aperture diameter D, and image size h by w. If we scale the lens by a factor of
							M, then f, D, h, and w are all scaled by M, but the F/# and FOV of the lens
							remain unchanged. If, when we scale the lens, the minimum resolvable spot size
							has not also increased by a factor of M, then we have increased the total
							number of points that can be resolved, thus increasing
							SBP.</p>

						<p>shows three different curves for SBP as a
							function of lens scale. The red R<sub>d</sub> curve shows the ideal,
							diffraction-limited case, where SBP increases quadratically with lens scale.
							Most lenses, however, are limited by geometrical aberrations. Then, as the
							green R<sub>g</sub> curve shows, SBP eventually reaches a plateau and stops
							increasing with lens scale. Since aberrations can be reduced by stopping down
							the lens aperture, lens designers typically increase F/# as a lens is scaled
							up. Then resolution does increase beyond the aberration limit, but at the cost
							of reduced light intensity, introducing a degradation in Signal-to-Noise Ratio
							(SNR). A general rule of thumb for conventional lens design is that F/#
							is made to increase like the cube root of lens scale. When this rule of thumb
							is applied, SBP no longer plateaus at the aberration limit, as shown by the
							blue R<sub>f</sub> curve. </p>

						<p>We present a different approach to increase SBP - the use of computations to
							correct for geometrical aberrations. In conventional lens design, resolution is
							limited by the spot size of the lens. For a lens with aberrations, spot size
							increases linearly with the scale of the lens. For a computational imaging
							system, resolution is related to deblurring error. <span style="font-style:
italic;">We observe, however, that for a lens with spherical aberrations,
								deblurring error does not increase linearly with lens scale</span>. We use this
							remarkable fact to derive a scaling law that shows that computational imaging
							can be used to develop cameras with very high resolution while maintaining low
							complexity and small size. The magenta R<sub>c</sub> curve in shows that, for a
							computational imaging system
							with a fixed SNR (i.e. fixed deblurring error), SBP scales more quickly with
							lens size than it does for conventional lens designs.</p>

						<table width="100%">
							<tbody>
								<tr>
									<td style="vertical-align: top;"><img id="figure_2"
											src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/scaling_laws_sans.png">
									</td>
									<td style="vertical-align: top;"><img id="figure_3"
											src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/scaling_laws_ours_sans.png">
									</td>
								</tr>
								<tr>
									<td>
										<div style="text-align: justify;padding-right:10px;">
											<span style="font-family: sans-serif;">
												<b>Figure 2</b>. A plot showing how Space-Bandwidth Product (SBP)
												increases as a
												function of lens size for a perfectly diffraction-limited lens
												(R<sub>d</sub>), a lens with geometric aberrations (R<sub>g</sub>), and
												a lens
												whose F/# increases with lens size (R<sub>f</sub>).
											</span>
										</div>
									</td>

									<td>
										<div style="text-align: justify;padding-left:10px;">
											<span style="font-family: sans-serif;">
												<b>Figure 3</b>. A new scaling law for computational imaging
												(R<sub>c</sub>). Note that R<sub>c</sub> not only improves upon
												the aberration limited curve R<sub>g</sub>, it also improves upon
												the conventional lens design curve R<sub>f</sub> <span
													style="font-style: italic;">without</span> requiring F/# to
												increase with lens scale.
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<br>
						<p><b>Proposed Architecture</b>. By using a large ball lens, an array of planar
							sensors, and deconvolution as a post processing step, we are able to capture
							gigapixel images with a very compact camera. The key to our architecture lays
							in the size of the sensors relative to the ball lens. Together, a ball lens and
							spherical image plane produce a camera with perfectly radial symmetry. We
							approximate a spherical image plane with a tessellated regular polyhedron. A
							planar sensor is placed on each surface of the polyhedron. Relatively small
							sensors are used so that each sensor occupies a small FOV and the image plane
							closely approximates the spherical surface. As a result, our camera produces a
							PSF that is not completely spatially invariant, but comes within a close
							approximation. </p>

						<p>The first system we demonstrate consists solely of a ball lens and an array
							of planar sensors. We use a 100mm acrylic ball lens and a 5 megapixel 1/2.5"
							Lu575 sensor from <a href="http://www.lumenera.com/">Lumenera</a> (see Figure 4). We emulate
							an image captured by multiple
							sensors by sequentially scanning the image plane using a pan/tilt motor. With
							this camera, a 1 gigapixel image can be generated over a roughly 60x40 degree
							FOV by tiling 14x14 sensors onto a 75x50mm image surface. When acquiring images
							with the pan/tilt unit, we allow a small overlap between adjacent images.</p>

						<p>Our first camera system is extremely compact, but it assumes there is no
							dead space between adjacent sensors. Sensors require at least some packaging
							around the active pixel area, so they can't be packed without introducing gaps
							in the camera's FOV. One possible solution to this problem is to use two or
							three camera systems together to cover the full FOV. Another solution is to
							introduce a secondary optic for each sensor, changing the system magnification
							so that the FOVs of adjacent sensors overlap slightly. This approach is taken
							in the implementation, shown in Figure 5, using custom
							optics manufactured by <a href="http://www.leioptics.com/">Lightwave
								Enterprises</a> and 5 NET-USA <a
								href="http://www.net-gmbh.com/e/IEEE1394_CMOS_FO1631TC.shtml">Foculus</a>
							sensors.</p>

						<table width="100%">
							<tbody>
								<tr>
									<td style="vertical-align: middle;"><img id="figure_4"
											src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/balllenssystem_sans.png">
									</td>
									<td style="vertical-align: middle;"><img id="figure_5"
											src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/system2_sans.png">
									</td>
								</tr>
								<tr>
									<td style="vertical-align: top">
										<div style="text-align: justify;padding-right:10px;">
											<span style="font-family: sans-serif;">
												<b>Figure 4</b>. Our single element gigapixel camera, which
												consists solely of a ball lens with an aperture stop. A gigapixel
												image is captured by sequentially translating a single 1/2.5", 5
												megapixel sensor with a pan/tilt motor. A final implementation
												would require a large array of sensors with no dead space in
												between them.
											</span>
										</div>
									</td>

									<td style="vertical-align: top">
										<div style="text-align: justify;padding-left:10px;">
											<span style="font-family: sans-serif;">
												<b>Figure 5</b>. A multiscale design based on our proposed
												architecture. An array of relay lenses modifies the system
												magnification so that the FOV of adjacent sensors overlaps
												slightly. The implementation is capable of capturing a 15 megapixel
												region of a gigapixel image. A full gigapixel camera requires 25x
												as many sensors and relay lenses.
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<br>
						<p><b>A Single Element Design</b>. The design in Figure
							4 is extremely compact, but impractical because adjacent sensors must be
							packed without any dead space in between them. The design in Figure 5 is practical enough
							for an implementation using
							off-the-shelf components, but is much less compact. The size of this system is
							limited by the package size of the sensor relative to the active sensor area.
							Sensors with a package size that is only 1.5x larger than the active sensor
							area are currently commercially available. With these sensors, it is possible
							to build a gigapixel camera that uses only a single optical element, as shown
							in the Zemax raytrace of Figure 6. In this design, each
							sensor is coupled with a smaller acrylic relay lens that decreases the focal
							length of the larger acrylic ball lens. The relay lenses share a surface with
							the ball lens, which means that it is possible to combine the entire optical
							system into a single element that may be manufactured by molding a single
							material, drastically simplifying the complexity (and hence alignment) of the
							system.</p>


						<table width="100%">
							<tbody>
								<tr>
									<td style="vertical-align: middle;"><img id="figure_6"
											src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/single_element_sans.png">
									</td>
									<td style="vertical-align: middle;"><img
											src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/single_element_animation.gif">
									</td>
								</tr>
								<tr>
									<td colspan="2">
										<div style="text-align: justify">
											<span style="font-family: sans-serif;">
												<b>Figure 6</b>. A single element design for a gigapixel camera.
												The design is a hybrid between the two implementations introduced
												in Figures 4 and 5. Each sensor is coupled
												with a lens that decreases focal distance, allowing FOV to overlap
												between adjacent sensors. The relay lenses share a surface with the
												ball lens, which means that it is possible to combine the entire
												optical system into a single element that may be manufactured by
												molding a single material.
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<br>
						<p><b>Capturing the Complete Sphere</b>. All of our proposed designs use a ball
							lens. A great advantage of using a ball lens is that, because it has perfect
							radial symmetry, a near hemispherical FOV can be captured. In fact, it can even
							be used to capture the complete sphere, as shown in Figure
							7. This design is similar to the camera proposed by Krishnan and Nayar, but it uses an array
							of planar megapixel sensors
							and relay lenses instead of a custom image sensor. Light passes through the
							gaps on one hemisphere, forming an image on a sensor located on the opposite
							hemisphere. As a result, the sensors cover the complete 2π FOV at the cost
							of losing roughly half the incident light.</p>

						<center><img id="figure_7"
								src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/full_sphere_array_sans.png">
						</center>
						<div style="text-align: justify;"><span style="font-family: sans-serif;">
								<b>Figure 7</b>. A design for a gigapixel camera with a 2<big>π</big>
								radian FOV. The design is similar to the implementation in Figures 4 and 5 with a large
								gap between adjacent
								lens/sensor pairs. Light passes through the gaps on one hemisphere, forming an
								image on a sensor located on the opposite hemisphere.
							</span></div>
					</td>
				</tr>

				<tr>
					<td align="left">
						<h3 id="Gallery of Captured Gigapixel Images">Gallery of Captured Gigapixel Images</h3>
						Click on the thumbnails below to view the captured gigapixel images in detail at gigapan.org.

						<br><br>
						<table width="100%">
							<tbody>
								<tr>
									<td style="padding: 10px 0">
										<a href="http://gigapan.org/gigapans/09d557515ee4cc1c8c2e33bf4f27485a/"
											target="_blank">
											<img
												src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/portrait.png">
										</a>
									</td>
									<td>
										<h2><a href="http://gigapan.org/gigapans/09d557515ee4cc1c8c2e33bf4f27485a/"
												target="_blank">
												Portrait</a></h2>
										Image Resolution: 65,000 x 25,000 = 1.6 gigapixels.
									</td>
								</tr>

								<tr>
									<td style="padding: 10px 0">
										<a href="http://gigapan.org/gigapans/0dca576c3a040561b4371cf1d92c93fe/"
											target="_blank">
											<img
												src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/closeup.png">
										</a>
									</td>
									<td>
										<h2><a href="http://gigapan.org/gigapans/0dca576c3a040561b4371cf1d92c93fe/"
												target="_blank">
												Still Life</a></h2>
										Image Resolution: 82,000 x 22,000 = 1.7 gigapixels.
									</td>
								</tr>

								<tr>
									<td style="padding: 10px 0">
										<a href="http://gigapan.org/gigapans/7173ad0acace87100a3ca728d40a3772/"
											target="_blank">
											<img
												src="https://cave.cs.columbia.edu/old/projects/gigapixel/images/skyline.png">
										</a>
									</td>
									<td>
										<h2><a href="http://gigapan.org/gigapans/7173ad0acace87100a3ca728d40a3772/"
												target="_blank">
												New York Skyline</a></h2>
										Image Resolution: 110,000 x 12,000 = 1.4 gigapixels.
									</td>
								</tr>
							</tbody>
						</table>



					</td>
				</tr>

				<tr>
					<td align="left">
						<h3 id="Acknowledgements">Acknowledgements</h3>
						This research was supported in part by DARPA Award No. W911NF-10-1-0214.
						Oliver Cossairt was<br> support by an NSF Graduate Research Fellowship. The
						authors thank Ravi Athale of Mitre Corporation for his comments and
						suggestions, Kieth Yeager of Columbia University for his help with machining
						the prototype components, and Lauren Kushner for her 3D modelling expertise.
					</td>
				</tr>

				<tr>
					<td align="left">
						<h3 id="Slides">Slides</h3>
						<blockquote>
							<p><a href="https://cave.cs.columbia.edu/old/projects/gigapixel/gigapixel_ICCP11.pptx">ICCP
									2011 presentation</a></p>

						</blockquote>
					</td>
				</tr>

				<!-- <tr>
					<td align="left">
						<h3 id="Related Project">Related Project</h3>
						<blockquote>
				<p><a href="../spherecam/">True Spherical Camera</a></p>

						</blockquote>
					</td>
				</tr> -->

				<tr>
					<td align="left">
						<h3 id="References">References</h3>
						<ul style="list-style-type: none;">
							<li>[1] Darpa at 50. <a
									href="http://www.darpa.mil/Docs/1-25013846_Eprint_200811141152151.pdf">www.darpa.mil/Docs/1-25013846_Eprint_200811141152151.pdf</a>,
								2010.</li>

							<li>[2] K. Fife, A. El Gamal, and H. Wong. "A 3MPixel Multi-Aperture Image Sensor with 0.7
								µm Pixels in 0.11 µm CMOS." <i>In IEEE ISSCC Conference</i>, 2008.</li>

							<li>[3] J. Goodman. <i>Introduction to Fourier optics.</i> Roberts &amp; Company Publishers,
								2005.</li>

							<li>[4] D. Marks and D. Brady. Gigagon: "A Monocentric Lens Design Imaging 40 Gigapixels."
								<i>In Imaging Systems</i>, OSA, 2010.

							</li>
							<li>[5] A. W. Lohmann. "Scaling laws for lens systems," <i>Appl. Opt.</i>, 28(23):4996–4998,
								1989.</li>

							<li>[6] G. Krishnan and S. K. Nayar. "Towards A True Spherical Camera," <i>In SPIE Human
									Vision and Electronic Imaging</i>, Jan, 2009.</li>
						</ul>
					</td>
				</tr>


			</tbody>
		</table>
	</div>

</template>

<script setup>
import categories from "@/assets/categories/catelogs.json"
const clb = categories.length !== 0;
</script>

<style scoped>
/* Body */
.container {
	text-decoration: none;
	font-size: 18px;
	line-height: 1.4em;
	margin: auto;
	width: 900px;
}

#Publications {
	text-align: left
}

/* sidebar */
.sidebar {
	font-size: 18px;
	float: right;
	top: 20px;
	margin-top: 18px;
	margin-right: -230px;
	text-align: left;
	position: sticky;
	list-style: none;
	line-height: 1.6em;
}

.sidetext {
	text-decoration: none;
	color: black;
	line-height: 1.8em;
}

.sidetext:hover {
	color: rgba(103, 103, 104, 0.842)
}

p {
	margin-top: 1em;
	margin-bottom: 1.5em;
}
</style>